body {
  background-color: #018281;
}

.window {
  position: absolute;
  overflow: auto;
}

.long-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

#registration-form {
  width: 90%;
  left: 5%;
  top: 20px;
}

#form-filler {
  width: 400px;
  right: 100px;
  top: 50px;
}

#candidate-info {
  width: 100%;
}

#form-filler img {
  display: inline-block;
  width: 15%;
}

#form-filler fieldset {
  display: inline-block;
  width: 75%;
}
